//import NavBar from "../components/global/nav_package";
import Land from "../components/package/Land";
//import Player from "../components/global/Video Player";
import Introduction from "../components/package/Intro";
import WhySection from "../components/package/Why";
import Steps from "../components/package/Steps";
import HeroImage from "../components/package/Hero Image";

function HirecoopPage() {
    return (
        <>
           {/* <NavBar />*/}
            <Land />
           {/* <Player url={'youtube link'} /> */}
            {/*<Introduction  h2="mahadev" h3="har har mahadev"  />*/}
            <Introduction h2="Hirecoop" h3="Speed Up Hiring with Hirecoop" />
            
            <WhySection p2="Hirecoop"   p1="Streamline your recruitment process with our powerful Hirecoop " />
            <Steps l1="The parser takes resumes in various formats such as PDF, Word, or plain text." l2="The parser takes resumes in various formats such as PDF, Word, or plain text." l3="The parser checks for errors and inconsistencies in the extracted data and corrects them." l4="The final output is a structured data format that can be easily integrated with various applicant tracking systems or databases for further processing." />
            <HeroImage /> 
        </>
    )   
}

export default HirecoopPage;