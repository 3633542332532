//import NavBar from "../components/global/nav_package";
import Land from "../components/package/Land";
import Plans from "../components/package/plans";
//import Player from "../components/global/Video Player";
import Introduction from "../components/package/Intro";
import WhySection from "../components/package/Why";
import Steps from "../components/package/Steps";
import HeroImage from "../components/package/Hero Image";
import Calendy from '../components/global/calendly';

function VAChatbotPackagePage() {
    return (
        <>
           {/* <NavBar />*/}
            <Land />
           {/* <Player url={'youtube link'} /> */}
            {/*<Introduction  h2="mahadev" h3="har har mahadev"  />*/}
            <Introduction h2="AI Virtual Assistant" h3="Virtual Assistant Development with Artizence " />
            
            <WhySection p2="Why Virtual Assistant?" p1="Virtual Assistants, 
            powered with natural language processing(nlp),
            understand and respond to human interactions. 
            From automating task to  recommendations , Virtual Assistant 
              applications span from personal assistance 
             personlized domain specific conversation." />

            <Plans 
                APrice="USD 499" Aheading="Consultation" Aintro="45min zoom call" AIncludes1="AI Roadmap" AIncludes2="AI Strategy" AIncludes3="" AIncludes4="" AIncludes5=""   
                BPrice="USD 1999" Bheading="Prototyping" Bintro="Prototype developed" BIncludes1="Consultation+" BIncludes2="" BIncludes3="" BIncludes4="" BIncludes5=""  
                CPrice="Let's Discuss" Cheading="Development" Cintro="Virtual Assistant Development" CIncludes1="Chatbot" CIncludes2="Voice recognition" CIncludes3="Model training/fine-tuning" CIncludes4="Data Preparation/Annotation" CIncludes5="Software Development/Integration"  />


            <Steps  l1="" l2="" />
            
            
            {/*<HeroImage /> */} 
        </>
    )
}

export default VAChatbotPackagePage;