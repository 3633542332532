import { useState, useEffect } from 'react'
import ai_operator from '../../assets/icons/AI Operator.svg'
import ai_chip from '../../assets/icons/AI Chip.png'
import pc_ai from '../../assets/icons/PC AI.png'
import human_ai from '../../assets/icons/Human AI.png'

function Services() {
    return (
        <section className="flex flex-row flex-wrap gap-5 justify-center max-w-full mx-4 overflow-hidden py-4">
           
             <Card image={ai_operator} heading='AI Development ' reverse={false} delay={100}>
           We have deep expertise in the design and development of AI applications and projects 
           tailored to real-world scenarios.


            </Card>
            
            <Card image={pc_ai} heading={'MVP Development'} reverse={true}  delay={150}>
            Minimum Viable Product (MVP) is first milestone in building an Awersome Product. We have deep experience in turning Ideas to Real world Products. Weather they are Mobile App or AI SaaS projects, we have build them :) 
           </Card>
            
            <Card image={ai_chip} heading={'AI Consultancy'} reverse={false} delay={200}>

            AI is revolutionizing the industry with automation and risk analysis  ,
             weather you are an Steel Factory in India or Financial Firm in New york . 
             AI revolutionizing every sector with Machine Learning , Deep Learning , Robotics Process Automation and generative AI.

            
            </Card>

            <Card image={human_ai} heading={'Model R&D'} reverse={true} delay={250}>
                    

            Models are the core components of AI systems. We excel in model training and development, including selection, training, evaluation, and preparing models for production. Our expertise also includes fine-tuning Language Models (LLMs) and Large Models for precise results.







            </Card>
        </section>
    )
}


function Card({ image, heading, reverse, children, delay }) {

    const [isMobile, setIsMobile] = useState()

    useEffect(() => {
        if (window.innerWidth < 680)
            setIsMobile(true)
        else
            setIsMobile(false)

        window.addEventListener('resize', (e) => {
            if (window.innerWidth < 680)
                setIsMobile(true)
            else
                setIsMobile(false)
        })
    }, [])


    if (isMobile === false)
        return (
            <div className={`flex ${reverse === true ? 'flex-col-reverse' : 'flex-col'} gap-2 items-center max-w-[300px] w-[100%] h-[420px] bg-[#fefafa] bg-opacity-30 rounded-[35px] py-[40px] px-[20px] font-poppins transition-[transform] duration-300
        hover:scale-105`} data-aos='fade-up' data-aos-delay={delay}>
                <img src={image} width={'140px'} height={'auto'} alt='services icon' />
                <p className='bold-text'>{heading}</p>
                <p className='text text-sm'>{children}</p>
            </div>
        )
    else
        return (
            <div className={`flex gap-9 items-center max-w-[450px] w-[95%] h-[200px] bg-[#fefafa] bg-opacity-30 rounded-[35px] py-[50px] px-[20px] font-poppins transition-[transform] duration-300
        hover:scale-105`} data-aos='fade-up'>
                <img src={image} width={'60px'} height={'auto'} alt='services icon' />
                <div className='flex flex-col justify-center items-center gap-2'>
                    <p className='bold-text'>{heading === 'Artificial Intelligence' ? 'A.I.' : heading}</p>
                    <p className='text max-w-[250px] text-xs'>{children}</p>
                </div>
            </div>
        )
}


export default Services;
