
function DesText() {
    return (
        <section className="flex flex-col text-[#0B0513] justify-center items-center gap-10 py-10 px-4 md:gap-14 md:py-14 lg:gap-18 lg:py-18">
            
                <center>
            <h4 data-aos="fade-down">
        
            Developing AI systems tailored for industry-specific applications is ushering in a new era of efficiency and innovation. These specialized AI solutions are designed to address the unique challenges and opportunities within various sectors, from manufacturing and healthcare to finance and logistics.                   

        </h4></center>

    
        </section>
    )
}

export default DesText;
