import pic from '../../assets/images/Profile data-cuate 1.png';

function Steps( prop ) {
    return (
        <section className="flex flex-col w-full py-4 max-w-[1200px] md:flex-row px-4 md:px-8 xl:m-auto justify-between">
            <img src={pic} width={'100%'} height={'auto'} className='max-w-[400px]' alt='profile data' />
            <div className='w-full max-w-[600px] rounded-xl px-6 py-6' 
            style={{
                boxShadow: '0px 0px 60px rgba(69, 27, 135, 0.6)'
            }}>
                <ol className='flex flex-col gap-2 md:gap-4 w-full h-full'>
                    <ListItem heading={'1. Input: '}>
                        { prop.l1 }
                    </ListItem>
                    <ListItem heading={'2. Data Extraction:'}>
                        { prop.l2 }
                    </ListItem>
                    <ListItem heading={'3. Data Validation:'}>
                    { prop.l3 }
                    </ListItem>
                    <ListItem heading={'4. Output:'}>
                    { prop.l4 }
                    </ListItem>
                </ol>
            </div>
        </section>
    )
}

function ListItem({heading, children}) {
    return (
        <li>
            <span className='bold-text text-dark-purple font-inter font-semibold'>{heading} </span> <br />
            <span className='text font-inter text-dark-purple-text max-w-[200px]'>{children}</span>
        </li>
    )
}

export default Steps;