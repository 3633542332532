

function ErrorPage() {
    return (
        <section className="absolute top-0 left-0 overflow-hidden flex justify-center items-center w-full h-screen bg-white z-50">
            <h2 className="H2">Page not found</h2>
        </section>
    )
}

export default ErrorPage;