import { useState, useEffect } from 'react';
import Pattern from '../../assets/images/Pattern.svg';
import Services from './Services';

function HomeLand() {

    const [isMobile, setIsMobile] = useState()

    useEffect(() => {
        if (window.innerWidth < 768)
            setIsMobile(true)
        else
            setIsMobile(false)

        window.addEventListener('resize', (e) => {
            if (window.innerWidth < 768)
                setIsMobile(true)
            else
                setIsMobile(false)
        })
    }, [])

    return (
        <section
            style={{
                backgroundImage: `url(${Pattern}), linear-gradient(182.37deg, #5E61D7 6.63%, #64409C 51.05%, #37215A 96.43%)`,
                backgroundRepeat: 'repeat',
                backgroundSize: 'contain',
                backgroundBlendMode: 'soft-light',
            }} >
            { isMobile === true ? <SmallScreenHero /> : <LargeScreenHero /> }
            <Services />

            {/* Wave div */}
            <div className="wave">
                <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                    <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" className="shape-fill"></path>
                </svg>
            </div>
            {/* Wave div */}

        </section>
    )
}

function LargeScreenHero() {
    return (
        <div> 
            <h1 className='H1 flex justify-center items-center h-[100vh] max-h-[100dvh] leading-[115%]'>
                Assist your E-com user with Chatbots and Recommendation  <br />
                systems</h1> 
          
        
        </div>
    )
}

function SmallScreenHero() {
    return (
        <div className='flex flex-row pt-28 pb-20 flex-wrap w-full justify-evenly items-center gap-4'>
            <h1 className='H1 flex justify-center items-center leading-[115%]'>
            Assist your E-com user with Chatbots and Recommendation  <br />
            systems</h1> 
        
            {/* 
            <button className='primary-btn h-12'>
                Explore
               </button>*/}
        </div>
    )
}

export default HomeLand;
