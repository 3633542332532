import Home from './pages/Home';
import AboutUs from './pages/About Us';
import BlogLanding from './pages/Blog Landing';
import Blog from './pages/Blog';
import Ecom from './pages/Ecom';
import PackagePage from './pages/Package';
import { Routes, Route } from "react-router-dom";
import HirecoopPage from './pages/Hirecoop';
import VAChatbotPackagePage from './pages/Vachatbot';


function AllRoutes() {
    return (
        <Routes>
        
          <Route path='/' element={<Home />} />
          <Route path='/ai-in-ecom' element={<Ecom />} />          
          <Route path='/about' element={<AboutUs />} />
          <Route path='/blog' element={<BlogLanding />} />
          <Route path='/blog/:blogID' element={<Blog />} />
          <Route path='/resume_parser' element={<PackagePage />} />
          <Route path='/hirecoop' element={<HirecoopPage />} /> 
          <Route path='/robotic-process-automation' element={<HirecoopPage />} /> 
          <Route path='/ai-mvp-development' element={<HirecoopPage />} /> 
          <Route path='/virtual_assistant' element={<VAChatbotPackagePage />} /> 

          </Routes> 
    )
}

export default AllRoutes;