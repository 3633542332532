const fetchBlog = (query, setMarkdown, setError, setHeading, setAuthor) => {

    var requestOptions = {
        method: 'GET', 
        }
      
      fetch(`https://artizence-blog-api-production.up.railway.app/blog/${query}`, requestOptions)
        .then(response => response.json())
        .then(result => {
            if('detail' in result)
            {
                setError(true)
                return;
            }
            setMarkdown(result.markdown)
            setHeading(result.heading)
            setAuthor(result.author)
            setError(false)
        })
        .catch(error => {
            setError(true)
        })
}

export { fetchBlog };