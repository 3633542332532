function sendEmail(email, subject, message) {
    /**
     * @description Taking argument/parameters for reusability
     */
    
    const data = {
        'email': email,
        'subject': subject,
        'message': message,
    }
    var requestOptions = {
        method: 'POST',
        redirect: 'follow',
        mode: 'no-cors'
      };

    let url = new URL('https://artizence.pythonanywhere.com/api/contact')
    for (let k in data) { url.searchParams.append(k, data[k]) }
    url = 'https://' + url.hostname + url.pathname + url.search
      
    fetch(url, requestOptions)
        .then(response => response.text())
            .then(result => alert('Message send successfully.'))
        .catch(error => alert('Internal server error. Retry.'));
}

export { sendEmail };