import heroimg from '../../assets/images/Resume Parser pc ios.png'

function HeroImage() {
    return (
        <section className='flex flex-col w-full py-20 max-w-[1200px] md:flex-row px-4 md:px-8 xl:m-auto justify-center items-center'>
            <img src={heroimg} width={'100%'} height={'70%'} alt='hero' className='max-w-[650px]' />
        </section>
    )
}

export default HeroImage;