import { useState, useEffect } from 'react'
import ai_operator from '../../assets/icons/AI Operator.svg'
import ai_chip from '../../assets/icons/AI Chip.png'
import pc_ai from '../../assets/icons/PC AI.png'
import human_ai from '../../assets/icons/Human AI.png'

function Services() {
    return (
        <section className="flex flex-row flex-wrap gap-5 justify-center max-w-full mx-4 overflow-hidden py-4">
            <Card image={ai_operator} heading='Artificial Intelligence' reverse={false} delay={100}>
        
          AI is enhancing Recruitment Process with tools like Resume Parser, and Resume Matcher Allowing Recruiters to select the best candidates easily.


        </Card>
            <Card image={pc_ai} heading={'NLP'} reverse={true}  delay={150}>
            Natural Language Processing(NLP) is a subfield of AI which deal with Linguistics and Text. NLP play a very significant role from chatGPT to Resume Parser intelligence.
           </Card>
            <Card image={ai_chip} heading={'Consultancy'} reverse={false} delay={200}>
               Artizence specialises in AI Consultancy. We have mechanised our process by understanding the problem to build a robust, scalable solution.

            </Card>
            <Card image={human_ai} heading={'Resume Parser '} reverse={true} delay={250}>
                Resume parsers are saving the recruiters time by seeing only the required information with Resume Matching, The Recruiters can easily select the best candidates. 
                    
            </Card>
        </section>
    )
}


function Card({ image, heading, reverse, children, delay }) {

    const [isMobile, setIsMobile] = useState()

    useEffect(() => {
        if (window.innerWidth < 680)
            setIsMobile(true)
        else
            setIsMobile(false)

        window.addEventListener('resize', (e) => {
            if (window.innerWidth < 680)
                setIsMobile(true)
            else
                setIsMobile(false)
        })
    }, [])


    if (isMobile === false)
        return (
            <div className={`flex ${reverse === true ? 'flex-col-reverse' : 'flex-col'} gap-2 items-center max-w-[300px] w-[100%] h-[420px] bg-[#fefafa] bg-opacity-30 rounded-[35px] py-[40px] px-[20px] font-poppins transition-[transform] duration-300
        hover:scale-105`} data-aos='fade-up' data-aos-delay={delay}>
                <img src={image} width={'140px'} height={'auto'} alt='services icon' />
                <p className='bold-text'>{heading}</p>
                <p className='text text-sm'>{children}</p>
            </div>
        )
    else
        return (
            <div className={`flex gap-9 items-center max-w-[450px] w-[95%] h-[200px] bg-[#fefafa] bg-opacity-30 rounded-[35px] py-[50px] px-[20px] font-poppins transition-[transform] duration-300
        hover:scale-105`} data-aos='fade-up'>
                <img src={image} width={'60px'} height={'auto'} alt='services icon' />
                <div className='flex flex-col justify-center items-center gap-2'>
                    <p className='bold-text'>{heading === 'Artificial Intelligence' ? 'A.I.' : heading}</p>
                    <p className='text max-w-[250px] text-xs'>{children}</p>
                </div>
            </div>
        )
}


export default Services;
