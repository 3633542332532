import whypic from '../../assets/images/Why package.png'

function WhySection( props ) {
    return (
        <section className='flex flex-col-reverse w-full max-w-[1200px] md:flex-row px-4 md:px-8 xl:m-auto'>
            <div className='flex flex-col w-full justify-between max-h-[410px] min-h-[350px]'>
                <div>
                    <p className='text font-montserrat text-dark-purple text-start font-medium'>WHY</p>
                    <h3 className='H3 font-montserrat text-dark-purple-text mt-2'> {props.p2} <br /> </h3>
                </div>
                <p className='text text-[#290E4B] font-montserrat text-start max-w-[500px]'>
                     
                     {props.p1}

                </p>
                <a href='https://calendly.com/akshatsrivastava'> 
                <button className='primary-btn py-4 rounded-md'>USE IT NOW!</button>
                </a>
            </div>
            <img src={whypic} width={'100%'} height={'auto'} className='max-w-[400px]' alt='why package'/> {/* Service Images  */}
        </section>
    )
}

export default WhySection;