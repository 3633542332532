import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { fetchBlogs } from "../../api/Blog Fetch"
import LoadingView from "./Loading"

function BlogList() {

    const [ blogs, setBlogs ] = useState([])
    const navigate = useNavigate()

    useEffect(() => {
        fetchBlogs(setBlogs)
    }, [])

    return (
        <>
            {   blogs.length > 0 &&
                <div>
                {
                    blogs.map((val, idx) => <BlogBox name={val.article_name.replace('.md', '')} heading={val.heading} navigator={navigate} key={idx} />)
                }
                </div>
            }
            {
                blogs.length === 0 &&
                <LoadingView />
            }
        </>
    )
}

const BlogBox = ({name, heading, navigator}) => {
    return (
        <div className="bg-blue-700 my-3"
        onClick={() => {
            navigator(`/blog/${name}`)
        }}>
            {name} - {heading} - Click box
        </div>
    )
}

export default BlogList;