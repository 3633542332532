import BG from '../../assets/images/Package BG.svg';

function Land() {
    return (
        <section className='pt-20 md:pt-24 h-[15vh] relative'
            style={{
                backgroundImage: `url('${BG}')`,
                backgroundRepeat: 'no-repeat',
                backgroundOrigin: '0px',
                backgroundSize: 'cover'
            }} >
        </section>
    )
}

export default Land;