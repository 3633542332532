import { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import HomeLand from '../components/home/Landing';
import CapabilitiesSection from '../components/home/Capabilities';
// import Portfolio from '../components/home/Portfolio';
import DesText from '../components/home/Description';
import Contact from '../components/home/Contact';

function Home() {

  useEffect(() => {
    document.title = 'Artizence : Empowering 21st Century with Artificial Intilligence';
    AOS.init({
      disable: false,
      startEvent: 'DOMContentLoaded',
      initClassName: 'aos-init',
      animatedClassName: 'aos-animate',
      useClassNames: false,
      duration: 400,
      easing: 'ease',
      once: true,
      mirror: false,
    })
  }, [])

  return (
    <>

      <HomeLand />
      <CapabilitiesSection />
      {/*<Portfolio />*/}
      <DesText />
      <Contact />
    </>
  );
}

export default Home;
