

function LoadingView() {
    return (
        <section className="flex justify-center items-center relative w-full h-[200px] flex-col gap-4">
            <div
                className="inline-block h-12 w-12 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite] text-dark-purple"
                role="status">
            </div>
            <span className="text-dark-purple-text">Processing...</span>
        </section>
    )
}

export default LoadingView;