//import NavBar from "../components/global/nav_package";
import Land from "../components/package/Land";
import Plans from "../components/package/plans";
//import Player from "../components/global/Video Player";
import Introduction from "../components/package/Intro";
import WhySection from "../components/package/Why";
import Steps from "../components/package/Steps";
import HeroImage from "../components/package/Hero Image";
import Calendy from '../components/global/calendly';

function PackagePage() {
    return (
        <>
           {/* <NavBar />*/}
            <Land />
           {/* <Player url={'youtube link'} /> */}
            {/*<Introduction  h2="mahadev" h3="har har mahadev"  />*/}
            <Introduction h2="RESUME PARSER" h3="Enhance your Recruitment with AI powered Resume Parser" />
            
            <WhySection p2="Resume Parser" p1="Streamline your recruitment process with our powerful resume parser - quickly and accurately extract relevant information from resumes and CVs to help make informed hiring decisions." />

            <Plans 
                APrice="USD 50" Aheading="Basic" Aintro="Best for startups" AIncludes1="this is A " AIncludes2="this is B" AIncludes3="this is B" AIncludes4="this is B" AIncludes5="this is B"   
                BPrice="USD 150" Bheading="Advance" Bintro="Best for startups" BIncludes1="this is A " BIncludes2="this is B" BIncludes3="this is B" BIncludes4="this is B" BIncludes5="this is B"   
                CPrice="USD 250" Cheading="Pro" Cintro="Best for startups" CIncludes1="this is A " CIncludes2="this is B" CIncludes3="this is B" CIncludes4="this is B" CIncludes5="this is B"   />


            <Steps  l1="The parser takes resumes in various formats such as PDF, Word, or plain text." l2="The parser takes resumes in various formats such as PDF, Word, or plain text." l3="The parser checks for errors and inconsistencies in the extracted data and corrects them." l4="The final output is a structured data format that can be easily integrated with various applicant tracking systems or databases for further processing." />
            
            
            {/*<HeroImage /> */} 
        </>
    )
}

export default PackagePage;