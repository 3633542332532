import amino from '../../assets/images/Package page ln.png'

const Introduction = ( props ) => {
    return (
        <section className="flex flex-col-reverse w-full max-w-[1200px] md:flex-row px-4 md:px-8 xl:m-auto justify-center items-center">
            <div className="w-full flex flex-col gap-8 py-4">


                <h2 className="H2 font-montserrat text-dark-purple underline decoration-2 underline-offset-[6px]">{props.h2}</h2>
                <h3 className="text-xl md:!leading-10 md:text-2xl lg:text-[35px] font-medium font-montserrat text-dark-purple-text md:max-w-[600px]">{props.h3} </h3>
            </div>
            <img src={amino} width={'100%'} height={'auto'} className='max-w-[500px]' alt='amico intro' />
        </section>
    )
}

export default Introduction;