import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import Land from "../components/blog/Land";
import ErrorPage from "../components/blog/Error";
import NavBar from '../components/global/Navigation Bar';
import Footer from "../components/global/Footer";
import LoadingPage from "../components/blog/Loading";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import { fetchBlog } from "../api/Blog Api";

function Blog() {

    const { blogID } = useParams()
    const [ markdown, setMarkdown ] = useState(``)
    const [ error, setError ] = useState(false)
    const [ heading, setHeading ] = useState('')
    const [ author, setAuthor ] = useState('')

    useEffect(() => {
        document.title = `Artizence : ${blogID}`

        fetchBlog(blogID, setMarkdown, setError, setHeading, setAuthor)

    }, [blogID])

    return (
        <>        
            { error === false && markdown &&  
                <>
                    <NavBar checkPath={false} />
                    <section className="flex flex-col justify-center">
                        <Land mainHeading={heading} author={author} />
                        <ReactMarkdown children={markdown} className="blog" />
                    </section>
                    <Footer checkPath={false} />
                </>
            }
            {
                error === true &&
                <ErrorPage />
            }
            {
                !markdown && error === false &&
                <LoadingPage />
            }
        </>
    )
}

export default Blog;