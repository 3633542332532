import Pattern from '../../assets/images/Pattern.svg';

function Land() {
    return (
        <section className='pt-20 md:pt-24 h-[65vh] md:h-[75vh] relative'
            style={{
                backgroundImage: `url(${Pattern}), linear-gradient(182.37deg, #5E61D7 6.63%, #64409C 51.05%, #37215A 96.43%)`,
                backgroundRepeat: 'repeat',
                backgroundSize: 'contain',
                backgroundBlendMode: 'soft-light',
            }} >
            <div className='flex items-end w-full h-[25vh] md:h-[40vh]'>
                <h1 className='H1 font-inter text-start w-full px-[3%] md:px-[10%]'> 
                    BLOGS
                </h1>
            </div>
        </section>
    )
}

export default Land;