import { useEffect, useState } from 'react';
import Logo from '../../logo.jpg';
import { useLocation } from 'react-router-dom';
import CallIcon from '../../assets/icons/Call icon.svg';
import WebIcon from '../../assets/icons/Web icon.svg';
import { Mail } from '@styled-icons/fluentui-system-filled';
import { LinkedinBox } from '@styled-icons/remix-fill';
import { Instagram } from '@styled-icons/remix-fill';
import { Twitter } from '@styled-icons/remix-fill';


function Footer({ checkPath = true }) {

    const [ isMobile, setIsMobile ] = useState()
    const withouSidebarRoutes = ['/blog/'];
    const { pathname } = useLocation()

    useEffect(() => {
        if(window.innerWidth < 820)
            setIsMobile(true)
        else
            setIsMobile(false)

        window.addEventListener('resize', (e) => {
            if(window.innerWidth < 820)
                setIsMobile(true)
            else
                setIsMobile(false)
        })
    }, [])

    if (withouSidebarRoutes.some((item) => pathname.includes(item)) && checkPath) return null;

    if(isMobile)
        return <FooterMobile />
    else
        return <FooterDesktop />

}

function FooterMobile() {
    return (
        <section className='flex flex-col gap-6 w-full bg-light-blue px-8 py-8'>
            <div className='flex flex-row w-full justify-between'>
                <div className='flex flex-row gap-4 items-center'>
                    <img src={Logo} width={'50px'} height={'auto'} alt="Logo" className="rounded-full border-2 border-solid border-[#5D0A96]" />
                    <h3 className='art-txt font-bold'>Artizence</h3>
                </div>
                <button className='w-max secondary-btn'>
                     
                </button>
            </div>
            <div className="flex flex-row w-full gap-24 mt-2 ml-[8%]">
                <div className='flex flex-col w-max gap-6'>
                    <Link href={'/'}>Home</Link>
                    <Link href={'/about'}>About</Link>
                    <Link href={'/'}>Work</Link>
                    <Link href={'/blog'}>Blog</Link>
                </div>
                <div className='flex flex-col w-max gap-6'>
                    {/*<Link href={'/'}>Services</Link> */}
                    <Link href={'/'}>Portfolio</Link>
                    {/*<Link href={'/'}>Discover</Link>*/}
                    {/*<Link href={'/'}>Help & Support </Link>*/} 
                </div>
            </div>
            <div className='w-full mt-6'>
                <div className='flex flex-col gap-2'>
                    <div className='flex flex-row gap-4'>
                        <img src={CallIcon} alt='call icon' />
                        <Text>+91-9369489827</Text>
                    </div>
                    <div className='flex flex-row gap-4 -translate-x-[3px]'>
                        <img src={WebIcon} alt='web icon' />
                        <Text>artizence.com</Text>
                    </div>
                    <div className='flex flex-row gap-6 w-full flex-wrap items-center'>
                        <button href='#'>

                            

                            <Text>
                            <Mail  className='h-auto w-[30px] lg:w-[50px] text-light-white' />  &nbsp;
                                hello@artizence.com
                            </Text>
                        </button>
                    </div>                
                            <br />
                    <div className='flex flex-row gap-6 w-full flex-wrap items-center' > 
                        <button></button>
                        <button></button>
                        <button></button>
                        <button></button>

                        <button href='https://www.linkedin.com/company/81895119'>
                            <LinkedinBox className='h-auto w-[30px] lg:w-[50px] text-light-white' />
                            
                        </button>
                        <a href='/'>
                            <Instagram className='h-auto w-[30px] lg:w-[50px] text-light-white' />
                        </a>
                        <a href='/'>
                            <Twitter className='h-auto w-[30px] lg:w-[50px] text-light-white' />
                        </a>
                    </div>
                </div>
            </div>
       
            <div className='flex flex-col w-full justify-center items-center gap-2 mt-4'>
                <hr className='border-light-white mt-4 mx-4 w-[100%]' />
                <div className='flex flex-row justify-between w-[100%]'>
                    <p className='footer-mini'>©Artizence 2022 - All Rights Reserved</p>
                    <div className='flex flex-row gap-3'>

                    </div>
                </div>
            </div>
        </section>
    )
}

function FooterDesktop() {
    return (
        <section className="flex-col gap-8 bg-light-blue py-10 px-10 items-center justify-center">
            <div className="flex flex-row w-full flex-wrap justify-center">
                <div className="flex flex-col w-1/2 justify-between min-w-[300px]">
                    <div className='flex flex-row gap-4 items-center'>
                        <img src={Logo} width={'65px'} height={'auto'} alt="Logo" className="rounded-full border-2 border-solid border-dark-purplr" />
                        <h3 className='art-txt text-xl'>Artizence</h3>
                    </div>
             
                </div>
                <div className="flex flex-row justify-center w-1/2 gap-24 mt-4">
                    <div className='flex flex-col w-max gap-6'>
                        <Link href={'/'}>Home</Link>
                        <Link href={'/about'}>About</Link>
                        <Link href={'/resume_parser'}>Resume Parser</Link>
                        <Link href={'https://calendly.com/akshatsrivastava'}>Contact us</Link>
                    </div>
                    <div className='flex flex-col w-max gap-6'>
                        <Link href={'/'}></Link>
                        <Link href={'/'}></Link>
                        <Link href={'/'}></Link>
                        <Link href={'/'}></Link>
                    </div>
                </div>
            </div>
            <div className='flex flex-row flex-wrap mt-16 items-center w-full'>
                <div className='w-full  max-w-[30%]'>
                    <div className='flex flex-col gap-4'>

                    <div className='flex flex-row gap-4'>
                    <Text>
                    hello@artizence.com </Text>

                    </div>
                        <div className='flex flex-row gap-4'>
                            <img src={CallIcon} alt='call icon' />
                            <Text>+91-9369489827</Text>
                        </div>
                        <div className='flex flex-row gap-4'>
                        
                            <Text>artizence.com</Text>
                        </div>
                    </div>
                </div>
                <div className='flex flex-row gap-6 w-full  max-w-[30%] flex-wrap justify-center items-center'>
              
                    <a href='/'>
                        <LinkedinBox className='h-auto w-[30px] lg:w-[50px] text-light-white' />
                    </a>
                    <a href='/'>
                        <Instagram className='h-auto w-[30px] lg:w-[50px] text-light-white' />
                    </a>
                    <a href='/'>
                        <Twitter className='h-auto w-[30px] lg:w-[50px] text-light-white' />
                    </a>
                </div>
                <div className='flex flex-row gap-4 w-full min-w-[260px] max-w-[30%]'>
                    <a href='/'>
                       
                    </a>
                    <a href='/'>
                       
                    </a>
                </div>
            </div>
            <div className='flex flex-col w-full justify-center items-center gap-2 mt-4'>
                <hr className='border-light-white mt-4 mx-4 w-[95%]' />
                <center> 
                <div className='flex flex-row justify-between w-[90%]'>
                    <p className='footer-mini'>©Artizence 2022 - present All Rights Reserved</p>
                    <div className='flex flex-row gap-5'>
                      
                    </div>
                </div>
                </center>
            </div>
        </section>
    )
}

function Link({ href, children }) {
    return (
        <a href={href} className='font-poppins font-[500] text-base lg:text-[18px] text-white ul-animate-link w-max'>
            {children}
        </a>
    )
}

const Text = ({ children }) => {
    return (
        <p className='font-poppins font-medium text-base text-light-white lg:text-[18px]'>
            {children}
        </p>
    )
}

export default Footer