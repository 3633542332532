import NavBar from './components/global/Navigation Bar';
import Footer from './components/global/Footer';
import AllRoutes from './Routes';
import { BrowserRouter } from "react-router-dom";

function App() {

  return (
    <BrowserRouter>
      <NavBar />
        <AllRoutes />
      <Footer />
    </BrowserRouter>
  );
}

export default App;


