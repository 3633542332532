import Logo from '../../Logo-without-bg.png';
import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Navigation } from '@styled-icons/fluentui-system-filled';
import { ArrowRight } from '@styled-icons/fluentui-system-filled';
//import { Link as ScrollLink } from 'react-scroll';
//import { Link as DOMLink } from 'react-router-dom';

function NavBar({ checkPath = true }) {

    const [ isMD, setMD ] = useState()
    const withouSidebarRoutes = ['/blog/', '/package'];
    const { pathname } = useLocation()

    useEffect(() => {
        if(window.innerWidth < 768)
            setMD(true)
        else
                setMD(false)

        window.addEventListener('resize', (e) => {
            if(window.innerWidth < 768)
                setMD(true)
            else
                setMD(false)
        })
    }, [])

    if (withouSidebarRoutes.some((item) => pathname.includes(item)) && checkPath) return null;

    if(isMD)
        return <NavBarMobile />
    else
        return <NavBarDesktop />
}
 
function NavBarDesktop() {
    return (
        <div name='desktop-navbar' className={`w-full h-24 text-black  flex-row justify-between items-center absolute top-0 z-50 px-16 transition-all hidden md:flex`} >
            <div className="flex items-center gap-4">
                <img src={Logo} alt='Icon' className="h-16 w-auto " />
                <p className='art-txt text-light'>Artizence</p>
            </div>
            <div className="flex gap-6 text-light ">
            <a href="/" className="nav-btn w-min text-light ">Home</a>

            <a href="/ai-in-ecom" className="nav-btn w-auto text-light ">AI in Ecom</a>
                {/*   <a href="/resume_parser" className="nav-btn w-min text-light ">Resume Parser</a>
            <a href="/Hirecoop" className="nav-btn w-min text-white ">Hirecoop</a>*/}

                    {/*<a href="/blog" className="nav-btn w-min text-black ">Blog</a>
                    <a href="/about" className="nav-btn w-min text-light ">About</a>
                */}
                    <a href="https://calendly.com/akshatsrivastava/30min" className="nav-btn-rev text-light ">Contact Us</a>
            </div>
        </div>
    )
}

function NavBarMobile() {

    const [ top, setTop ] = useState(-100)

    return (
        <>
            <div name='nav-bar-mobile-top' className='flex text-black flex-row justify-between items-center absolute top-0 left-0 w-full h-20 z-20 px-8'>
              <div className="flex items-center  text-black   gap-4">
                <img src={Logo} alt='Icon' className="h-10 w-auto  rounded-full border-2 border-solid border-dark-purple" />
                <p className='art-txt text-white '>Artizence</p>
            </div>
            <button onClick={() => {
                if(top === -100)
                    setTop(0)
                else
                    setTop(-100)
            }}>
                { top === 0 ? <ArrowRight size={'30px'} color={'#fff'} /> : <Navigation size={'30px'} color={'#fff'} />}
            </button>
            </div> 
            <div className={`flex justify-center items-center text-black bg-dark-white h-screen w-full z-10 fixed ${top === 0 ? 'top-0' : 'top-[-100vh]'} transition-all`}>
                <div className="flex flex-col gap-6">
                    {/* 
                    <a href="/resume_parser" className="nav-btn w-min text-black ">Resume Parser</a>
                    <a href="/Hirecoop" className="nav-btn w-min text-black ">Hirecoop</a>

                    {/* <a href="/blog" className="nav-btn w-min text-black ">Blog</a> 
                    <a href="/about" className="nav-btn w-min text-black ">About</a>
                    */}
                    <a href="https://calendly.com/akshatsrivastava/30min" className="nav-btn-rev text-black ">Contact Us</a>
                </div>
            </div>
        </>
    )
}

export default NavBar;
