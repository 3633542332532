import circular_cap from '../../assets/images/Circular BG.svg'
import { useEffect, useState } from 'react'
import { Link } from 'react-scroll';


function CapabilitiesSection() {

    const [isMobile, setIsMobile] = useState()

    useEffect(() => {
        if (window.innerWidth < 900)
            setIsMobile(true)
        else
            setIsMobile(false)

        window.addEventListener('resize', (e) => {
            if (window.innerWidth < 900)
                setIsMobile(true)
            else
                setIsMobile(false)
        })
    }, [])

    return (
        <section className="relative flex flex-col pt-10 gap-24 items-center pb-[30px]" id='Capabilities'>
            <h1 className="H3 font-montserrat text-[#0F0E0E] tracking-[0.08em] text-center md:leading-[30px] md:text-4xl md:text-2xl" data-aos='fade-down'>
            Generative AI-backed conversational AI and recommendation systems are helping businesses enhance their user experience and create more value for their users.
            </h1>

            {isMobile === false && <CircularCapDesktop />}
            {isMobile === true && <CircularCapMobile />}
            
            <h2 className="H2 font-inter text-[#0D0C0C] tracking-[0.08em] text-center md:leading-[55px] md:text-4xl lg:text-5xl" data-aos='fade-up'>
              Assist your users with AI 
            </h2>
            <Link className="primary-btn text-center cursor-pointer select-none" to="Contact-Us" smooth={'easeInCubic'} ignoreCancelEvents={true}>
                
            </Link>
        </section>
    )
}

function CircularCapDesktop() {
    return (
        <div className={'flex flex-row select-none gap-10 justify-between items-center flex-wrap my-2'}>
            <div className='flex flex-col gap-16 items-end w-[300px]' data-aos='fade-right'>
                <Button classes={'translate-x-10 lg:text-xl '}>Conversation AI </Button>
                <Button classes={'translate-x-2 lg:text-xl '}>Recommendation System</Button>
                <Button classes={'lg:text-xl '}>Personilized Feed for users  </Button>
                <Button classes={'translate-x-10 lg:text-xl '}>Assist users with A.I. 24/7 </Button>
            </div>
            <div className='relative text-center min-w-[200px]' data-aos='fade-up' data-aos-offset={'-50px'}>
                <img src={circular_cap} width={'200px'} height={'auto'} alt='Circular Bg' className='lg:w-[300px]' />
                <div className='absolute w-[168px] h-[168px] bg-[#FFFBFB] rounded-full top-0 left-1/2 -translate-x-1/2 translate-y-[10%] font-inter font-bold text-2xl flex justify-center items-center text-[#444C5B] lg:w-[250px] lg:h-[250px] lg:text-3xl'>
                    Our Core <br />
                    Capabilities
                </div>
            </div>
            <div className='flex flex-col gap-16 w-[300px] self-start' data-aos='fade-left'>
                <Button classes={'-translate-x-10 lg:text-xl'}>Chatbot</Button>
                <Button classes={'translate-x-6 lg:text-xl'}>AI in Ecom </Button>
                <Button classes={'translate-x-5 lg:text-xl'}>Customer Service 24*7 </Button>
            </div>
        </div>
    )
}

function CircularCapMobile() {
    return (
        <div className='flex justify-center items-center w-full' data-aos='fade-right'>
            <div className={'flex flex-row select-none gap-8 items-center my-2 px-2 py-2 overflow-hidden 6H:gap-16'}>
                <div className='relative text-center min-w-[175px]'>
                    <img src={circular_cap} width={'175px'} height={'auto'} alt='Circular Bg' className='6H:w-[300px]' />
                    <div className='absolute w-[146px] h-[146px] bg-[#FFFBFB] rounded-full top-0 left-1/2 -translate-x-1/2 translate-y-[10%] font-inter font-bold text-xl flex justify-center items-center text-[#444C5B] 6H:w-[250px] 6H:h-[250px] 6H:text-3xl'>
                        Our Core <br />
                        Capabilities
                    </div>
                </div>
                <div className='flex flex-col gap-2 items-center' data-aos='fade-left'>
                    <Button classes={'-translate-x-28 text-sm 6H:text-base'}>Conversation AI </Button>
                    <Button classes={'-translate-x-16 text-sm 6H:text-base'}>Recommendation System</Button>
                    <Button classes={'-translate-x-10 text-sm 6H:text-base'}>Personilized Feed</Button>
                    <Button classes={'-translate-x-4 text-sm 6H:text-base'}>Assist users with A.I. 24/7</Button>
                    <Button classes={'-translate-x-10 text-sm 6H:text-base'}>Chatbot</Button>
                    <Button classes={'-translate-x-16 text-sm 6H:text-base min-w-[180px]'}> Place order  </Button>
                    <Button classes={'-translate-x-28 text-sm 6H:text-base'}>Customer Service 24*7</Button>
                </div>
            </div>
        </div>
    )
}

const Button = ({ children, classes }) => {
    return (
        <button className={`bg-[#9D62CA] font-roboto font-semibold text-[#f0f4f8] text-base py-3 px-5 rounded-2xl w-max cursor-default transition-all ${classes} hover:scale-[1.05]`}>
            {children}
        </button>
    )
}

export default CapabilitiesSection;
