import Land from '../components/blog landing/Land';
import BlogList from '../components/blog landing/Blog List';

function BlogLanding() {
    return (
        <section>
            <Land />
            <BlogList />
        </section>
    )
}

export default BlogLanding;