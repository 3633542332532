import { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Land from '../components/about/Land';
import AboutDescription from '../components/about/About description';
import Team from '../components/about/Team';

function AboutUs() {

    useEffect(() => {
        document.title = 'Artizence : About'
        AOS.init({
            disable: false,
            startEvent: 'DOMContentLoaded',
            initClassName: 'aos-init',
            animatedClassName: 'aos-animate',
            useClassNames: false,
            duration: 400,
            easing: 'ease',
            once: true,
            mirror: false,
          })
    }, [])


    return (
        <>
            <Land />
            <AboutDescription />
           {/* <Team /> */}
        </>
    )
}

export default AboutUs;