function Plans( props ) {
    return (
        <>

            <div className="flex items-center py-12 flex-wrap justify-center flex-row" >
                
                
                <div className="bg-purple-300 hover:scale-105 shadow py-3  rounded-lg  md:h-[60vh] mx-8 my-4 md:w-[20%]  lg:h-[72vh] lg:w-[20%]" >
                    
                    <center> 
                        {/* Heading */}
                        <h2 className="text-4xl font-bold text-black  text font-montserrat " >{ props.Aheading }</h2>
                       
                            <br />

                        {/* Price */}
                        <h3 className="text-4xl font-semibold text-black  text-bold font-montserrat" >
                        { props.APrice }
                        </h3>

                            <br />
                        
                        {/* One line intro */}
                        <p className="px-4 text-black  font-montserrat" >
                            <i> 
                            { props.Aintro }
                            </i> 
                        </p>

                            <br/>

                        {/* benefits */}                

                        <section className="text text-black  font-montserrat  "> 
                        
                        <h3 class="H3 text-xl text text-black  font-white" >includes</h3>

                            <ul className='flex text-black   flex-col py-2 justify-center'>

                                <ListItem info={props.AIncludes1 }>
                                </ListItem>
                                <ListItem info={props.AIncludes2 }>
                                </ListItem>
                                {/*
                                <ListItem info={props.AIncludes3 }>
                                </ListItem>
                                <ListItem info={props.AIncludes4 }>
                                </ListItem>
                                <ListItem info={props.AIncludes5 }>
                                </ListItem>
                            
                                */}
                            </ul>

                        </section>

                        {/* button */}
                        <button className='primary-btn  font-montserrat py-2 rounded-md'>USE IT NOW!</button>


                        </center>


                </div>

                <div className="bg-purple-300 hover:scale-105 shadow py-3  rounded-lg  md:h-[60vh] mx-8 my-4 md:w-[20%]  lg:h-[72vh] lg:w-[20%]" >
                    
                    <center> 
                        {/* Heading */}
                        <h2 className="text-4xl font-bold text-black  text font-montserrat " >{ props.Bheading }</h2>
                       
                            <br />

                        {/* Price */}
                        <h3 className="text-4xl font-semibold text-black font-montserrat" >
                        { props.BPrice }
                        </h3>

                            <br />
                        
                        {/* One line intro */}
                        <p className="px-4 text-black  font-montserrat" >
                            <i> 
                            { props.Bintro }
                            </i> 
                        </p>

                            <br/>

                        {/* benefits */}                

                        <section className="text text-black  font-montserrat  "> 
                        
                        <h3 class="H3 text-xl text-black font-montserrat" >includes</h3>

                            <ol className='flex flex-col py-2 justify-center'>

                                <ListItem info={props.BIncludes1 }>
                                </ListItem>
                                <br />
                                {/*
                                <ListItem info={props.BIncludes2 }>
                                </ListItem>
                                <ListItem info={props.BIncludes3 }>
                                </ListItem>
                                <ListItem info={props.BIncludes4 }>
                                </ListItem>
                                <ListItem info={props.BIncludes5 }>
                                </ListItem>
                                 */}
                            
                            </ol>

                        </section>

                        {/* button */}
                        <button className='primary-btn  font-montserrat py-2 rounded-md'>USE IT NOW!</button>


                        </center>


                </div>

                <div className="bg-purple-300 hover:scale-105 shadow py-3  rounded-lg  md:h-[60vh] mx-8 my-4 md:w-[20%]  lg:h-[72vh] lg:w-[20%]" >
                    
                    <center> 
                        {/* Heading */}
                        <h2 className="text-4xl text text-black font-bold font-montserrat " >{ props.Cheading }</h2>
                       
                            <br />

                        {/* Price */}
                        <h3 className="text-4xl text-black font-semibold font-montserrat" >
                        { props.CPrice }
                        </h3>

                            <br />
                        
                        {/* One line intro */}
                        <p className="px-4 text-black font-montserrat" >
                            <i> 
                            { props.Cintro }
                            </i> 
                        </p>

                            <br/>

                        {/* benefits */}                

                        <section className="text font-montserrat  "> 
                        
                        <h3 class="H3 text-xl text-black font-montserrat" >includes</h3>

                            <ol className='flex flex-col py-2 justify-center'>

                                <ListItem info={props.CIncludes1 }>
                                </ListItem>
                                <ListItem info={props.CIncludes2 }>
                                </ListItem>
                                <ListItem info={props.CIncludes3 }>
                                </ListItem>
                                <ListItem info={props.CIncludes4 }>
                                </ListItem>
                                <ListItem info={props.CIncludes5 }>
                                </ListItem>
                            
                            </ol>

                        </section>

                        {/* button */}
                        <button className='primary-btn  font-montserrat py-2 rounded-md'>USE IT NOW!</button>


                        </center>


                </div>
                
            </div>

        </>

    )
}


function ListItem({info}) {
    return (
        
            <p> 
                <center>
                    <span className='text text-black font-montserrat w-full text-dark-white-text px-4 '>- {info} </span> <br />
                </center>
            </p>
    )
}

export default Plans; 