import Logo350 from '../../logo350.png';
import AboutAmico from '../../assets/images/About Us Amico.png';

function AboutDescription() {
    return (
        <section className='flex flex-col items-center pt-3 pb-16 gap-10'>
            <div className='flex flex-row text-black max-w-5xl'>
                <div className='max-w-[340px]' data-aos='fade-right'>
                    <img src={Logo350} alt='logo'/>
                </div>
                <div className='flex flex-col justify-center gap-4 max-w-[700px]' data-aos='fade-left'>
                    <h2 className='H2 font-monsterrat'> Artizence </h2>
                    <p className='bold-text text-black font-monsterrat max-w-[500px] text-sm md:text-lg'> 
                      Empowering 21st Century with Artificial Intillgence
                    </p>
                </div>
            </div>
            <div className='flex flex-col gap-4 max-w-7xl text-black px-6'>
                <p className='text text-black font-monsterrat text-justify max-w-[100%]' data-aos='fade-down'>
                The evolution of Artificial Intelligence is propelling human civilization towards exponential growth. Artizence enables companies to embrace AI, empowering them to better assist their customers and teams, leading to increased efficiency and accelerated growth.                </p>
                <div className='flex flex-col gap-4 items-center md:justify-between md:flex-row'>
                    <div className='max-w-[500px] md:min-w-[425px] min-w-[120px]' data-aos='fade-right'>
                        <img src={AboutAmico} alt='amico'/>
                    </div>
                    <p className='text text-black font-monsterrat text-justify' data-aos='fade-left'>
                    The saying "Necessity is the mother of invention" holds true as we witnessed the emergence of Stream Engine and Industrial Revolution, which significantly enhanced human power. As the limitations of human muscle power became apparent, science came to the rescue, allowing us to overcome these limitations. Now, Artificial Intelligence is playing a pivotal role in augmenting human wisdom. Embracing AI will undoubtedly propel industrial output to unprecedented heights!

</p>
                </div>
            </div>
            <h4 className='H4 font-monsterrat text-black max-w-6xl px-4 text-center' data-aos='fade-up'>
                "Every time we create something new we go from zero to one." -
                <br />
                Peter Theil 
            </h4>
        </section>
    )
}

export default AboutDescription;
