import { useState } from 'react';
import bg from '../../assets/images/Contact BG Pattern.svg';
import ContactUsImage from '../../assets/images/Contact Us image.svg';
import { sendEmail } from '../../api/Contact Api';

function Contact() {

    const [ email, setEmail ] = useState('');
    const [ name, setName ] = useState('');
    const [ phone, setPhone ] = useState('');
    //const [ country, setCountry ] = useState('');
    //const [ org, setOrg ] = useState('');
    const [ message, setMessage ] = useState('');

    const formattedMsg = `
        Hello there,
        We will contact you soon regarding the query below.
        Name: ${name}
        Email: ${email}
        Message: ${message}
        Phone No: ${phone}
        regards,
        Admin
    `

    const subject = `Thank You!`

    return (
        <section className="flex justify-center flex-col items-center relative overflow-hidden text-black" id='Contact-Us'
        style={{
            backgroundImage: `url("${bg}")`,
        }}>
            {/* <img src={bg} width={'100%'} height='100%' alt='bg' className='block absolute top-0 left-0 -z-10 object-cover min-h-[700px]' /> alternative */}
            <div className='flex flex-col items-center gap-12 relative w-[90%] bg-dark-purple rounded-[40px] my-16 py-5 pb-8 max-w-[1250px]'>
                <div className='flex flex-col items-center gap-5'>
                    <h5 className='bold-text font-poppins font-semibold'>EXPLORE?</h5>
                    <div className='flex flex-col items-center'>
                        <a href='https://calendly.com/akshatsrivastava/30min'>
                        <button className="primary-btn bg-white font-poppins font-semibold  text-dark-purple md:text-lg min-w-[150px]">
                            Let's Discuss 
                        </button>
                        </a>
                        <p className='bold-text mt-1'>hello@artizence.com</p>
                    </div>
                </div>
                <div className='flex flex-row w-full px-8 gap-8 flex-wrap justify-center'>
                    <div className='flex justify-center items-center w-[45%] max-w-[600px] min-w-[300px]'>
                        <img src={ContactUsImage} width={'300px'} height='auto' alt='contact us pic' />
                    </div>
                    <div className='flex flex-col gap-3 w-[45%] max-w-[600px] min-w-[300px]'>
                        <div className='flex flex-col gap-3 w-full md:flex-row'>
                            <input className='input-text'  type='text' placeholder='Name' 
                            onChange={(e) => setName(e.currentTarget.value)} />
                            <input className='input-text' type='number' placeholder='Phone No.'
                            onChange={(e) => setPhone(e.currentTarget.value)} />
                        </div>
                        <div className='flex flex-col gap-3 w-full md:flex-row'>
                            <input className='input-text'  type='text' placeholder='E-Mail'
                            onChange={(e) => setEmail(e.currentTarget.value)} />

                            {/* <input className='input-text' type='text' placeholder='Country'
                            onChange={(e) => setCountry(e.currentTarget.value)} /> */}
                        </div>
                        {/* <div className='flex flex-col gap-3 w-full md:flex-row'>
                            <input className='input-text max-w-full md:max-w-[50%]'  type='text' placeholder='ORG'
                            onChange={(e) => setOrg(e.currentTarget.value)} />
                        </div> */}
                        <textarea className='textarea mt-2' placeholder='Subject' rows={3}
                        onChange={(e) => setMessage(e.currentTarget.value)} />
                        <button className="primary-btn bg-white font-poppins font-semibold  text-dark-purple text-base min-w-[150px] max-w-full"
                        onClick={() => {
                            sendEmail(email, subject, formattedMsg)
                        }}>
                            Submit
                        </button>
                    </div>
                </div>
            </div>
             
           {/* <p className='bold-text text-[#FDF7F7] pb-5'>MADE IN INDIA</p> */}
        </section>
    )
}

export default Contact;
