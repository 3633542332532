import Pattern from '../../assets/images/Pattern.svg';

function Land({ mainHeading, author}) {
    return (
        <section className='pt-20 md:pt-24 h-[65vh] md:h-[100vh] relative'
            style={{
                backgroundImage: `url(${Pattern}), linear-gradient(182.37deg, #5E61D7 6.63%, #64409C 51.05%, #37215A 96.43%)`,
                backgroundRepeat: 'repeat',
                backgroundSize: 'contain',
                backgroundBlendMode: 'soft-light',
            }} >
            <div className='flex items-end w-full h-[25vh] md:h-[50vh] text-[#F5F3FF]'>
                <div className='w-full'>
                    <h1 className='H1 font-montserrat font-[700] text-start w-full px-[3%] md:px-[10%]'> 
                        {mainHeading}
                    </h1>
                    <h3 className='H3 font-montserrat font-[700] w-full px-[3%] md:px-[10%] mt-2 md:mt-4'>
                        {author}
                    </h3>
                </div>
            </div>
        </section>
    )
}

export default Land;