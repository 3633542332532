
function DesText() {
    return (
        <section className="flex flex-col text-[#0B0513] justify-center items-center gap-10 py-10 px-4 md:gap-14 md:py-14 lg:gap-18 lg:py-18">
            
                <center>
            <h4 data-aos="fade-down">
        
            Chatbots and recommendation systems are helping e-commerce users find the products they are looking for more easily.
             Chatbots can answer questions and provide assistance in real time,
              while recommendation systems can suggest products  based on thousands of data points.
               This can help users to discover new products that they might like, 
               and it can also help them to find the best combinations of products.
                These technologies can improve the customer experience and increase sales for e-commerce businesses.
        </h4></center>

    
        </section>
    )
}

export default DesText;
