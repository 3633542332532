const fetchBlogs = (setBlogs) => {
    const data = fetch('https://artizence-blog-api-production.up.railway.app/blog', { method: 'GET'})
    data
    .then(response => response.json())
    .then(result => {
        setBlogs(result.blogs)
    })
    .catch(err => {})
}

export { fetchBlogs }